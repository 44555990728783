/*  */

.inputdesign {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  width: 100%;
}
.placeholder {
  color: #9e9999;
  font-size: 0.9rem;
  margin: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.39rem;
  padding-bottom: 0.39rem;
  white-space: nowrap;
}
.dropstyle {
  margin-top: 9px;
  border: 1px solid #e9e3e3;
  box-shadow: 1px 10px 13px 4px #c2bfbf;
  height: 46vh;
  overflow: scroll;
  position: absolute;
  background: white;
  width: 18%;
  z-index: 1;
}
.dropdowntxt {
  color: black;
  margin: 5px;
  padding: 5px;
}
.dropdowntxt:hover {
  background-color: #e7e7ed;
}

.css-6actro-control {
  border-color: #e5e7eb !important;
}


.loginSwiper .swiper-pagination{
  display: grid !important;
  grid-template-columns: repeat(3,1fr) !important;
  gap: 10px !important;
}



/* weekend_management Table */

.weekend_management .fc .fc-today-button,
.weekend_management .fc .fc-goToDateButton-button{
  background: #fff;
  /* border: 1px solid #5679FF !important;
  color: #5679FF !important; */
  padding: .3rem 1rem;
  font-size: 1rem !important;
}
.weekend_management .fc .fc-today-button:hover,
.weekend_management .fc .fc-goToDateButton-button:hover{
  /* border: 1px solid #5679FF !important; */
  background: #5679FF !important;
  color: #fff !important;
}

.weekend_management .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  border: 1px solid #5679FF !important;
  background: #5679FF !important;

}

.weekend_management .fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: rgba(165, 210, 255, 0.9) 0px 0px 0px 0.2rem !important;
}
.weekend_management .fc .fc-button-primary:focus {
  box-shadow: rgba(165, 210, 255, 0.9) 0px 0px 0px 0.2rem !important;
}

.weekend_management .fc-goToDateButton-button,
.weekend_management .fc .fc-button-primary:disabled:hover {
  color: #fff !important;
  background: #5679FF !important;
}

.weekend_management .fc .fc-toolbar.fc-header-toolbar {
  border: 1px solid #DDDDDD !important;
  border-bottom: 0 !important;
  padding:  0.7em 1em;
  margin-bottom: 0;
}

.weekend_management .fc .fc-toolbar-title {
  font-size: 1.2rem !important;
  color: #5E5873 !important;
  font-weight: 500;
}

.weekend_management .fc-bg-event{
  opacity: 1 !important;
}

.fc-next-button,.fc-prev-button{
  background: #F1F5FA !important;
  border: 0 !important;
  padding: 0.4em 0.35em !important;
  color: #7D7D7D !important;
  font-size: .6rem;
}


.weekend_management .fc-goToDateButton-button,
.weekend_management .fc-todayButton-button,
.weekend_management .fc-today-button,
.weekend_management .fc .fc-button-primary:disabled {
  color: black !important;
  font-weight: 500 !important;
  border-color: #A1A1A1 !important;
  background: white !important;
}

.weekend_management > .fc-toolbar-chunk:last-child > .fc-button-group:first-child > button:disabled{
  color: black !important;
  font-weight: 500 !important;
  border-color: #A1A1A1 !important;
}

/* .weekend_management .fc-icon.fc-icon-chevron-left::before {
  content: '\2BC7';
}

.weekend_management .fc-icon.fc-icon-chevron-right::before {
  content: '\2BC8';
} */

.weekend_management .fc .fc-button .fc-icon {
  font-size: 1rem;
  vertical-align: baseline;
}

.weekend_management table th{
  color: #6E6B7B;
  font-weight: 600;
}