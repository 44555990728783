@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

:root {
  --primary-bg: #EFF5FD;
}

:root {
  /* Primary Colors */
  --primary-blue: #5679FF;
  --primary-green: #7BD093;
  --primary-black: #101928;
  --primary-dark-green: #3AA966;
  --primary-orange: #E3830A;
  --primary-red: #F52F2F;
  --primary-background: #f7f8fa;
  --primary-lightGray: #DADDE8;
  --primary-text: #646F80;

  /* Secondary Colors */
  --secondary-white: #EFF5FD;
  --secondary-light-green: #DEF8E8;
  --secondary-light-blue: #D7DFFF;
  --secondary-peach: #FEE3C1;
  --secondary-pink: #FBDADA;
  --secondary-pure-white: #FFFFFF;

  /* Text Colors */
  --text-black: #000000;
  --text-dark-gray: #3F4046;

  /* Gradient Colors */
  --gradient-blue: linear-gradient(to right, #2D418C, #4E70F2);
  --gradient-blue-green: linear-gradient(to right, #5679FF, #3AA966);
  --gradient-light-blue: linear-gradient(to right, #C9D4FF, #C8FDD7);
  --gradient-dark: linear-gradient(to right, #101928, #38405C);


  --gray7: #B1BAB8
}

body {
  margin: 0;

  font-family: "Inter", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Ubuntu", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-overflow-scrolling: touch; */
}

html {
  /* -webkit-overflow-scrolling: touch; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .arabic{
  font-family: 'IBM Plex Sans Arabic', sans-serif;
} */

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::selection {
  background-color: #101928;
  color: #fff;
}

.shadow-login-card {
  box-shadow: rgb(0 0 0 / 24%) 0px 1px 10px;
  transition-duration: 0.2s;
}

.shadow-popup-card {
  box-shadow: rgb(0 0 0 / 24%) 0px 1px 10px;
}

.cardShadow {
  box-shadow: 0px 1px 4px 0px #5C60701A;
}

.shadow-login-card:hover {
  transform: scale(1.02);
  background-color: #f6fafc;
}

.shake-bottom {
  -webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-21 12:44:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-bottom
 * ----------------------------------------
 */
@-webkit-keyframes shake-bottom {

  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

@keyframes shake-bottom {

  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

.swiper-pagination-bullet.paggination {
  border: 1px solid #7b7b7b !important;
  background-color: transparent !important;
  opacity: 1 !important;
  height: 0.75rem !important;
  width: 0.75rem !important;
}

.swiper-pagination-bullet-active.paggination {
  background-color: #101928 !important;
}

.loginSwiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0 !important;
}

.slide-in-bottom_ {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.loginSwiper .swiper-wrapper {
  padding-bottom: 4rem;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-21 15:55:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5679ff;
  border-radius: 1.375rem;
}

.before-plus::before {
  content: "+";
  line-height: 1px;
  color: #a1a1a1;
  font-size: 1.2rem;
}

.scroll-hidden-lg::-webkit-scrollbar {
  display: none;
}

.rounded-checkbox {
  position: relative;
}

.rounded-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 1.75rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.75rem;
  transform: scale(0.6) translate(-50%, -20%);
}

.rounded-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 0.375rem;
  left: 0.438rem;
  opacity: 0;
  position: absolute;
  top: 0.5rem;
  transform: rotate(-45deg);
  width: 0.75rem;
}

.rounded-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.rounded-checkbox input[type="checkbox"]:checked+label {
  background-color: #5679ff;
  border-color: #5679ff;
}

.rounded-checkbox input[type="checkbox"]:checked+label:after {
  opacity: 1;
}


.payment-checkbox {
  position: relative;
  padding: 0 0.475rem;
}

.payment-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 1.75rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.75rem;
  transform: scale(0.6) translate(0%, -20%);
  padding: 0 10px;
}

.payment-checkbox label:after {
  border: 2px solid #5679ff;
  border-top: none;
  border-right: none;
  content: "";
  height: 0.375rem;
  left: 0.438rem;
  opacity: 0;
  position: absolute;
  top: 0.5rem;
  transform: rotate(-45deg);
  width: 0.75rem;
}

@media screen and (max-width: 1023px) {
  .payment-checkbox label:after {
    height: 0.375rem;
    width: 0.85rem;
  }
}

@media screen and (max-width: 375px) {
  .payment-checkbox label:after {
    height: 0.575rem;
    width: 0.95rem;
  }
}

.payment-checkbox input[type="checkbox"] {
  visibility: hidden;
}




.payment-checkbox input[type="checkbox"]:checked+label {
  background-color: #fff;
  border-color: #5679ff;

}

.payment-checkbox input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.scroll-blur {
  position: relative;
}

.scroll-blur::after {
  content: "";
  height: 30%;
  background: linear-gradient(to top,
      #101928 0.55%,
      rgba(16, 25, 40, 0) 99.45%);
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  position: absolute;
}

.slide-in-right {
  /* -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-25 16:28:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.toggle-checkbox:checked {
  right: 0;
  border-color: #5679ff;
}

.toggle-checkbox:checked+.toggle-label {
  background-color: #5679ff;
}

@media screen and (min-width: 991px) and (max-width: 1230px) {
  :root {
    font-size: 12px;
  }

  .btnCustomHight {
    height: 2.5rem !important;
  }
}

.overlay_responsive {
  display: none;
  z-index: 1231;
}

@media screen and (max-width: 1023px) {
  .responsive_sidebar~.overlay_responsive {
    display: none;
  }

  .responsive_sidebar:not(.hidden)~.overlay_responsive {
    display: block;
  }

  .responsive_sidebar {
    z-index: 1231231;
    position: fixed;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-9-20 14:47:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  .scroll-hidden::-webkit-scrollbar {
    display: none;
  }

  .scroll-blur::after {
    display: none;
  }

  .slide-in-right {
    /* -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
  }

  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.admin-card-scroll-shadow::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  background: linear-gradient(190.63deg,
      rgba(255, 255, 255, 0) -177.76%,
      rgba(255, 255, 255, 0) -177.73%,
      rgba(255, 255, 255, 0.7) 92.07%);
  height: 3rem;
  z-index: 1;
}

.preloader-wrapper {
  position: relative;
}

.preloader-wrapper span {
  width: 25px;
  height: 25px;
  position: relative;
  background: #fff;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  animation-name: preloader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.preloader-sm span {
  width: 10px;
  height: 10px;
  margin-right: 3px;
}

.preloader-wrapper span:nth-child(1) {
  animation-delay: 0.15s;
}

.preloader-wrapper span:nth-child(2) {
  animation-delay: 0.3s;
}

.preloader-wrapper span:nth-child(3) {
  animation-delay: 0.45s;
}

@keyframes preloader {
  0% {
    background-color: #fff;
    transform: translateY(0);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  }

  50% {
    background-color: #5679ff;
    transform: translateY(-10px);
    box-shadow: 0 1.25rem 3px rgba(0, 0, 0, 0.15);
  }

  100% {
    background-color: #fff;
    transform: translateY(0);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  }
}

.active_sidebar path {
  stroke: #fff !important;
}

.slide-in-elliptic-top-fwd {
  /* -webkit-animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-3 15:27:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.dark-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: white;
}

.react-daterange-picker__inputGroup__input {
  outline: none;
}

.react-daterange-picker__wrapper {
  border: 1px solid #e5e7eb !important;
  padding: 0.4rem;
  border-radius: 0.2rem;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
  font-family: "Inter", sans-serif !important;
  background-color: #f9f9f9 !important;
  border-radius: 30px;
  overflow: hidden;
  padding: 1rem;
}

.react-calendar__tile {
  border-radius: 50%;
  padding: 0 !important;
  height: 45px;
  width: 45px;
  font-size: 0.875rem;
  flex: none;
  margin: auto !important;
}

@media screen and (max-width: 540px) {
  .react-calendar__tile {
    height: 35px;
    width: 35px;
  }
}

.react-calendar__tile--now {
  background-color: #ddd !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #afafb1 !important;
}

.react-calendar__tile--active {
  background-color: #5679ff !important;
  color: #ffff !important;
}

.eact-calendar__tile.react-calendar__month-view__days__day {
  font-weight: 500;
}

.react-calendar__month-view__weekdays__weekday:nth-of-type(1) {
  color: #afafb1 !important;
}

.react-calendar__month-view__weekdays__weekday:last-of-type {
  color: #afafb1 !important;
}

abbr:where([title]) {
  text-decoration: none !important;
  font-weight: bold;
}

.bounce-in-right {
  /* -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both; */
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-5 16:3:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/* 
.react-calendar__tile {
  height: 46px;
  width: 46px;
} */

.react-calendar__month-view__days {
  flex-wrap: wrap;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
}

.customize-active {
  background-color: #eef5fb;
  border-color: #eef5fb;
  position: relative;
}

.customize-active path {
  fill: #5679FF;
}

.customize-active::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M6.99967 0.583496C3.45592 0.583496 0.583008 3.45641 0.583008 7.00016C0.583008 10.5439 3.45592 13.4168 6.99967 13.4168C10.5434 13.4168 13.4163 10.5439 13.4163 7.00016C13.4163 3.45641 10.5434 0.583496 6.99967 0.583496ZM9.78101 5.91516C9.83222 5.85662 9.87121 5.78843 9.89568 5.71459C9.92015 5.64076 9.92961 5.56278 9.9235 5.48524C9.91739 5.40769 9.89583 5.33216 9.86009 5.26307C9.82436 5.19398 9.77516 5.13274 9.71541 5.08295C9.65566 5.03315 9.58655 4.99581 9.51215 4.97312C9.43775 4.95042 9.35956 4.94284 9.28219 4.95081C9.20482 4.95878 9.12982 4.98215 9.06161 5.01953C8.9934 5.05692 8.93335 5.10756 8.88501 5.1685L6.37667 8.17791L5.07876 6.87941C4.96874 6.77315 4.82139 6.71436 4.66844 6.71569C4.51549 6.71702 4.36918 6.77836 4.26103 6.88652C4.15288 6.99467 4.09153 7.14098 4.0902 7.29393C4.08887 7.44688 4.14767 7.59423 4.25392 7.70425L6.00392 9.45425C6.06124 9.51153 6.12986 9.55624 6.20542 9.58553C6.28097 9.61482 6.3618 9.62805 6.44275 9.62437C6.5237 9.6207 6.60299 9.60019 6.67558 9.56417C6.74817 9.52815 6.81245 9.4774 6.86434 9.41516L9.78101 5.91516Z' fill='%235679FF'/%3E%3C/svg%3E");
  position: absolute;
  background-size: contain;
  background-position: center;
  top: 10px;
  right: 10px;
  height: 1.25rem;
  width: 1.25rem;
}

.input-right-icon svg {
  height: 0.75rem;
  width: 0.75rem;
}

.input-right-icon path {
  fill: #a1a1a1;
}

.before-left-arrow {
  position: relative;
}

.before-left-arrow::before {
  content: "";
  position: absolute;
  left: -2rem;
  top: 50%;
  height: 1.25rem;
  width: 1.25rem;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%);
  cursor: pointer;
}

.before-left-arrow-close::before {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.27539 11.7251L7.12053 6.36255L1.27539 1' stroke='%23A1A1A1' strokeWidth='1.43001' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
}

.before-left-arrow-open::before {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.835938 1.43994L6.19849 7.28508L11.561 1.43994' stroke='%23A1A1A1' strokeWidth='1.43001' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
}

.odd-bg:nth-child(2n-1) {
  background-color: #fbfcff;
}

.scale-in-center {
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-18 11:16:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }

  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

.line-clamp-1 {
  display: -webkit-box;
  /* word-break: break-all; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  /* word-break: break-all; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  /* word-break: break-all; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  :root {
    font-size: 11px;
  }
}

@media screen and (max-width: 370px) {
  :root {
    font-size: 9px;
  }
}

.schedule-grid-item {
  border-right: 0.2px solid #3f3f441a;
  border-bottom: 0.2px solid #3f3f441a;
  /* padding: 1.4rem 1rem; */
  font-size: 0.875rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.remove-indicator-dropdown#remove-indicator-dropdown [class*="IndicatorsContainer"][class*="IndicatorsContainer"],
.remove-indicator-dropdown#remove-indicator-dropdown [class*="indicatorSeparator"][class*="indicatorSeparator"],
.remove-indicator-dropdown#remove-indicator-dropdown [class*="control"][class*="control"]:nth-child(2),
.remove-indicator-dropdown#remove-indicator-dropdown .css-1wy0on6 {
  display: none !important;
}

.appointment-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 0.75rem;
}

.reward-gradient-1 {
  background: linear-gradient(60deg, #fa7070 13.4%, #7140ff 86.6%);
}

.reward-gradient-2 {
  background: linear-gradient(60deg, #fa7099 13.4%, #ff7040 86.6%);
}

.reward-gradient-3 {
  background: linear-gradient(210deg, #ffbe99 13.4%, #fd9094 86.6%);
}


.vorcher-gradient-1 {
  background: linear-gradient(60deg, #415FD2 13.4%, #67A4EC 86.6%);
}

.vorcher-gradient-2 {
  background: linear-gradient(60deg, #804BC5 13.4%, #A06FDF 86.6%);
}

.vorcher-gradient-3 {
  background: linear-gradient(210deg, #EFA25C 13.4%, #F2874B 86.6%);
}

.vorcher-gradient-4 {
  background: linear-gradient(210deg, #CB4F95 13.4%, #DD68D8 86.6%);
}

.slide-in-bottom_ {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-13 12:52:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  min-height: 30px !important;
  height:fit-content !important;
} */

.appointment_done_Card {
  background-color: #E8E8E8;
  border-top: 4px solid #808080;
}

.appointment_done_Card .duration-fill {
  fill: #808080;
}

.appointment_done_Card .duration-type {
  background-color: #808080;
}

.green-bg-card {
  background-color: #C7FBCF;
  border-top: 4px solid #039500;
}

.green-bg-card .duration-fill {
  fill: #039500;
}

.green-bg-card .duration-type {
  background-color: #039500;
}


.brown-bg-card {
  background-color: #F0DBAF;
  border-top: 4px solid #B99470;
}

.brown-bg-card .duration-fill {
  fill: #B99470;
}

.brown-bg-card .duration-type {
  background-color: #B99470;
}

.yellow-bg-card {
  background-color: #fffae2;
  border-top: 4px solid #ffba08;
}

.yellow-bg-card .duration-fill {
  fill: #ffba08;
}

.yellow-bg-card .duration-type {
  background-color: #ffba08;
}

.gray-bg-card {
  background-color: #E8E8E8;
  border-top: 4px solid #808080;
}

.gray-bg-card .duration-fill {
  fill: #808080;
}

.gray-bg-card .duration-type {
  background-color: #808080;
}

.void-bg-card {
  background-color: #f2a174;
  border-top: 4px solid #c34620;
}

.void-bg-card .duration-fill {
  fill: #c34620;
}

.void-bg-card .duration-type {
  background-color: #c34620;
}

.red-bg-card {
  background-color: #F5BCBC;
  border-top: 4px solid #B90000;
}

.red-bg-card .duration-fill {
  fill: #B90000;
}

.red-bg-card .duration-type {
  background-color: #B90000;
}

.is_unavailable_typeee {
  text-align: center;
}

.unavailable-bg-card {
  /* display: none; */
  background-color: #ddd;
  border-bottom: 4px solid #ddd;
  opacity: 0.4;
  z-index: -1;
}

.unavailable-bg-card .duration-time {
  display: none;
}

.unavailable-bg-card .duration-fill {
  fill: #b180e5;
  display: none;
}

.unavailable-bg-card .duration-type {
  background-color: #efe0ff;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.75 5.25V0H5.25V5.25H0V6.75H5.25V12H6.75V6.75H12V5.25H6.75Z' fill='%235679FF'/%3E%3C/svg%3E") !important;
  background-position: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='2' viewBox='0 0 14 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.152175 2H13.8478C13.9315 2 14 1.90526 14 1.78947V0.210526C14 0.0947368 13.9315 0 13.8478 0H0.152175C0.0684786 0 9.53674e-07 0.0947368 9.53674e-07 0.210526V1.78947C9.53674e-07 1.90526 0.0684786 2 0.152175 2Z' fill='%235679FF'/%3E%3C/svg%3E") !important;
}

.accordion-item:first-of-type,
.accordion-button:not(.collapsed),
.accordion-item:not(:first-of-type) {
  border: none !important;
}

.accordion-button,
.accordion-button:not(.collapsed) {
  background-color: #f1f8ff;
}

.accordion-button:not(.collapsed) {
  font-weight: bold;
  color: #000 !important;
}

@media screen and (min-width: 1740px) {
  .nstyle-container {
    max-width: 1640px;
    margin: auto;
  }

  .nstyle-container:has(.landing_page) {
    max-width: 100%;
  }
}

/* 
div[id^='react-select']:is([id$='listbox']) {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  max-width: 40vw !important;
} */

.word-break {
  word-break: break-word;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

/* 

.custom-tooltip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  filter: drop-shadow(0px 2.18408px 3.36816px rgba(0, 0, 0, 0.25));
  z-index: 1;
}

.custom-tooltip.top {
  transform: translate(-55%, -110%);
}

.custom-tooltip.top .custom-tooltip-arrow {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -1%) rotate(270deg);
}

@media screen and (max-width:1024px) {
  .custom-tooltip {
    transform: translate(-50%, -100%);
  }
}

.custom-tooltip-arrow {
  width: 20px;
  transform: translate(-50%, -50%);
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #FBFCFF;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(0%, -50%);
}

.custom-tooltip-inner {
  position: relative;
  padding: 1rem;
  border-radius: 12px;
  background: #FBFCFF;
}

.tooltip_ {
  display: none;
  cursor: pointer;
}

.tooltip_opener:hover+.tooltip_ {
  display: block;
  transition-duration: 0.2s;
}

.tooltip_:hover {
  display: block;
} */

.__react_component_tooltip {
  font-size: 0.75rem;
  max-width: 20rem;
}

.custom-tooltip-shadow {
  filter: drop-shadow(0px 2.18408px 2.36816px rgba(0, 0, 0, 0.05));
  z-index: 123123213123321 !important;
  border-radius: 15px !important;
  opacity: 1 !important;
  white-space: break-spaces !important;
  min-width: 9rem;
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-27 15:48:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-right {
  /* -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-1 12:38:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.shadow-appoint {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* h1,
h2,
h3,
h4,
h5 {
  @apply font-semibold
} */

.hover-parent:hover .hover-child {
  display: flex;
  flex-direction: column;
  z-index: 122;
}

.bg-green-gradient {
  background-image: linear-gradient(180deg, #cae040 0%, #23cc67 100%);
}

/* p{
  overflow-wrap: anywhere;
} */

.fill-primary {
  fill: #5679ff;
}

.text-primary {
  color: #101928;
}

.bg-primary {
  background-color: #101928;
}

h1 {
  text-transform: capitalize;
}

.border-primary {
  border-color: #101928;
}

div[class$="-indicatorContainer"] {
  padding: 0.35rem !important;
}

div[class$="-indicatorContainer"] svg {
  height: 1.25rem;
  width: 1.25rem;
}

span[class$="-indicatorSeparator"] {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

div[class$="-ValueContainer"] {
  padding: 0.025rem 0.5rem;
}

div[class$="-control"] {
  border-color: hsl(0, 0%, 80%) !important;
}

.error div[class$="-control"] {
  border-color: #eb3b3b !important;
}

div[class$="-control"]:hover {
  border-color: hsl(0, 0%, 80%) !important;
}

div[class$="-control"] {
  min-height: 2rem;
  padding: 0.088rem 0.25rem;
}

/* div[class$="-singleValue"] {
  font-size: 0.75rem;
  text-transform: lowercase;
} */

div[class$="-singleValue"] .text-xs::first-letter {
  text-transform: uppercase !important;
}

div[class$="-Input"] {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  min-width: 5ch;
  margin: 1px;
  font-size: 0.875rem;
}

div.custom__menu-portal,
div[class$="-MenuPortal"] {
  z-index: 12321321312321321321;
  touch-action: none;
}

.discount-gradient-1 {
  background-image: linear-gradient(67.91deg, #fa7070 10.11%, #b261cf 81.23%);
}

.discount-gradient-2 {
  background-image: linear-gradient(67.91deg, #496ff7 10.11%, #61aecf 81.23%);
}

.discount-gradient-3 {
  background-image: linear-gradient(67.91deg, #28cd38 10.11%, #61cfa1 81.23%);
}

.discount-gradient-4 {
  background-image: linear-gradient(67.91deg, #fa9130 10.11%, #edbe44 81.23%);
}

.discount-gradient-5 {
  background-image: linear-gradient(67.91deg, #7943ec 10.11%, #a257ec 81.23%);
}

.discount-gradient-6 {
  background-image: linear-gradient(67.91deg, #49b6a2 10.11%, #3b9786 81.23%);
}

.discount-gradient-7 {
  background: linear-gradient(90deg,
      rgba(131, 58, 180, 1) 0%,
      rgba(253, 29, 29, 1) 50%,
      rgba(252, 176, 69, 1) 100%);
}

.discount-gradient-8 {
  background: linear-gradient(0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(253, 187, 45, 1) 100%);
}

.discount-gradient-9 {
  background: linear-gradient(0deg,
      rgba(34, 193, 195, 1) 0%,
      rgb(165, 168, 232) 100%);
}

.discount-gradient-10 {
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 50%,
      rgba(60, 32, 104, 1) 73%,
      rgba(125, 67, 179, 0.9557072829131653) 91%,
      rgba(76, 40, 122, 1) 100%,
      rgba(148, 149, 91, 1) 100%,
      rgba(0, 212, 255, 1) 100%);
}

.active-check {
  position: relative;
  border: 2px solid #496ff7;
}

.active-check::after {
  height: 1.2rem;
  content: "";
  width: 1.2rem;
  background-image: url("/public/images/check.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1212313;
}

.AppointmentCard_Drag {
  user-select: none;
}

.AppointmentCard_Drag {
  transition-property: width;
  transition-duration: 0.2s;
}

.loyalty-gradient {
  position: relative;
  background-color: #e7ecff;
}

.loyalty-gradient::after,
.loyalty-gradient::before {
  content: "";
  background-image: url("/public/images/Union-Circle.svg");
  background-position: center;
  position: absolute;
  height: 92%;
  width: 18px;
  z-index: 2;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loyalty-gradient::after {
  transform: translateX(-50%);
  left: 0;
}

.loyalty-gradient::before {
  transform: translateX(50%);
  right: 0;
}

.btn {
  font-weight: 600;
  white-space: nowrap;
  color: white;
  padding-top: 0.75rem;
  padding-bottom: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* border-width: 2px; */
  border-radius: 0.35rem;
  gap: 0.75rem;
  align-items: center;
  width: fit-content;
  display: flex;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.15rem;
}

.react-tooltip-lite {
  background-color: #f3f5ff;
  border: 1px solid #f0f0f0;
  text-align: center;
  @apply shadow text-sm rounded-lg !max-w-[30rem];
}

.chart_canvas {
  /* width: 100% !important; */
}

.react-daterange-picker__wrapper {
  @apply px-2 py-[0.62rem] rounded-md;
}

.react-daterange-picker__button {
  padding: 0px 0.375rem !important;
}

.react-daterange-picker__button svg {
  transform: scale(0.8);
}

.react-daterange-picker__wrapper {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* 
.custom__value-container--is-multi {
  overflow: auto !important;
  flex-wrap: nowrap !important;
}
.custom__multi-value {
  min-width: 14ch !important;
} */

.input {
  border-radius: 4px;
  font-size: 0.875rem;
  outline: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.407rem;
  padding-bottom: 0.407rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border-width: 1px;
  border-color: hsl(0, 0%, 80%);
}

.input:focus {
  border-color: var(--primary);
  border-left-width: 0.375rem;
}

.input:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* disabled:cursor-not-allowed rounded-md text-sm outline-none lg:px-4 px-2 py-[0.8rem] placeholder:text-[#A1A1A1] text-primary focus:border-primary ease-in transition-all focus:border-l-[0.375rem] */

/* .border-l,
.border-b,
.border-t,
.border-r,
.border {
  border-color: hsl(0, 0%, 80%);
} */

.theme-btn {
  @apply px-3 py-[9px] text-white text-[0.7rem] leading-tight uppercase rounded-md hover:shadow-lg font-semibold focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap;
  background-color: var(--primary-blue);
  width: fit-content;
  border: 1px solid var(--primary-blue);
  /* padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: box-shadow 150ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: fit-content;
  border: 1px solid var(--primary); 
  gap: 0.5rem;
  color: #fff;
  background-color: var(--primary); */
}

.theme-btn2 {
  @apply px-3 py-[9px] text-primary-black text-[0.7rem] leading-tight uppercase rounded-md hover:shadow-lg font-semibold focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex items-center whitespace-nowrap;
  background-color: white;
  width: fit-content;
  border: 1px solid var(--primary-lightGray);
}

.theme-btn3 {
  @apply px-3 py-[10px] text-primary-black text-[0.7rem] leading-tight uppercase rounded-md hover:shadow-lg font-semibold focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex items-center whitespace-nowrap;
  background-color: #E4EAF0;
  width: fit-content;
  border: 0px;
}

.theme-btn-appointment-status {
  @apply px-3 py-[9px] text-white text-[0.7rem] leading-tight uppercase rounded-md hover:shadow-lg font-semibold focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap;
  background-color: #2196F3;
  width: fit-content;
  border: 1px solid #2196F3;
}

.custom-theme-btn {
  @apply px-3 py-[9px] text-white text-[0.7rem] leading-tight uppercase rounded-md hover:shadow-lg font-semibold focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap;
  width: fit-content;
}

.custom-theme-btn-reschedule,
.custom-theme-btn-checkout {
  @apply px-3 py-[9px] text-[#5679FF] text-[0.7rem] leading-tight uppercase rounded-md hover:shadow-lg font-semibold focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap;
  width: fit-content;
}

.theme-btn.borderBtn {
  background-color: #fff;
  color: #101928;
}

.theme-btn.deleteBtn {
  color: #fff;
  background-color: #CE0000;
  border: 1px solid #CE0000;
}

.theme-btn.successBtn {
  color: #fff;
  background-color: #28CD38;
  border: 1px solid #28CD38;
}

/* ------------ New Success & Delete Btns  */
.theme-btn.newDeleteBtn {
  color: #fff;
  background-color: #ce0000;
  border: 1px solid #ce0000;
  text-transform: capitalize;
  padding: 10px 15px;
  border-radius: 12px;
}

.theme-btn.newDeleteBtnFocus {
  color: #ce0000;
  background-color: #CE000026;
  border: 1px solid #ce0000;
  text-transform: capitalize;
}

.theme-btn.newSuccessBtn {
  color: #fff;
  background-color: #28cd38;
  border: 1px solid #28cd38;
  text-transform: capitalize;
  padding: 10px 15px;
  border-radius: 12px;
}

.theme-btn.newSuccessBtnFocus {
  color: #28cd38;
  background-color: #28CD3826;
  border: 1px solid #28cd38;
  text-transform: capitalize;
}

.theme-btn.bueBorderBtn {
  color: #5679FF;
  border: 1px solid #7C98FF;
  background: #ffffff;
}

.theme-btn svg {
  height: 0.85rem;
  width: 0.85rem;
}

.btn-outlined {
  @apply px-3 py-[9px] text-black text-[0.7rem] leading-tight uppercase rounded-md hover:shadow-lg font-semibold focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap bg-transparent border;
  border-color: var(--gray7);
}


@media screen and (max-width: 550px) {
  .calendar__icon {
    display: block;
  }
}


@keyframes blinkingAnimation {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }
}

.blinking-card {
  animation: blinkingAnimation 1.3s infinite;
  /* Adjust the duration as needed */
}

/* date-picker css */

.date-picker-container {
  display: inline-block;
  position: relative;
}

.custom-datepicker {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.input__error__style {
  border: 1px solid #eb3b3b !important;
  border-left: 0.375rem solid #eb3b3b !important;
}

.custom__input-container {
  margin: 0 !important;
}


@media print {
  .not_show_in_print {
    display: none;
  }

  .print_padding_top_20 {
    padding-top: 20px;
  }

  .print_style {
    max-height: fit-content !important;
    overflow-y: visible;
  }

  .invoice__print {
    position: absolute !important;
    max-height: 90% !important;
    margin-top: 20px !important;
    align-items: start !important;
  }
}


.appointment__card:hover .tool__tip__card {
  display: block;
}

.appointment__card:hover {
  z-index: 6;
}

.bg_gradient-blue-tb {
  background: linear-gradient(360deg, #445594 0%, #5679FF 100%);
}

.bg_gradient-blue-tr {
  background: linear-gradient(295.7deg, #4E70F2 -0.98%, #2D418C 97.17%);
}

.select-business-card:hover {
  background: var(--gradient-blue);
}

.select-business-card:hover .text-black {
  color: #fff;
}

.select-business-card:hover svg rect {
  fill: #fff;
}

.feature-bg {
  background-image: url('/public/images/noise-bg.png'), var(--gradient-blue);
  background-size: 100% 64rem;
  background-repeat: no-repeat;
  background-color: white;
}

[fill="#5679FF"] {
  fill: #5679FF !important;
}

@media (max-width: 768px) {
  .feature-bg {
    background-size: 100% 80rem;
  }
}

.bg-blueGreen-gradient {
  background: linear-gradient(270deg, #C8FDD7 0%, #C9D4FF 100%);
}

.font-manrope {
  font-family: "Manrope", serif !important;
}

.feature-cta-bg {
  background: linear-gradient(270deg, #C8FDD7 0%, #FFFFFF 61%, #C9D4FF 100%);
}