.loading-wrapper {
  position: relative;
}

.loading-wrapper span {
  width: 25px;
  height: 25px;
  position: relative;
  background: #fff;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  animation-name: preloader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.loading-wrapper span:nth-child(1) {
  animation-delay: 0.15s;
}

.loading-wrapper span:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-wrapper span:nth-child(3) {
  animation-delay: 0.45s;
}
